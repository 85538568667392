<template>
  <v-autocomplete
    :value="value"
    v-bind="$attrs"
    v-on="$listeners"
    item-text="name"
    item-value="id"
    :loading="loading || fetching"
    :items="items"
    :label="$t('hr.suggestion.category')"
    color="tertiary"
    clearable
    @input="$emit('input', $event)"
  >
    <template #item="{ item }">
      <v-list-item-avatar size="30" :color="item.color">
        <span class="white--text font-weight-bold">{{ item.name.substring(0, 2).toUpperCase() }}</span>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-html="item.name" />
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
  import { SUGGESTION_CATEGORIES } from "../query";

  export default {
    name: "SuggestionCategoryPicker",
    inheritAttrs: false,
    props: {
      value: String,
      loading: Boolean
    },
    data: () => ({
      fetching: false,
      items: []
    }),
    methods: {
      fetchItems() {
        const options = {
          query: SUGGESTION_CATEGORIES,
          fetchPolicy: "no-cache"
        };
        this.fetching = true;
        this.$apollo
          .query(options)
          .then(({ data: { error, suggestionCategories }, errors }) => {
            if (!error && !errors) {
              this.items = suggestionCategories;
            }
          })
          .catch(e => this.$helpers.showNotification(e.message))
          .finally(() => (this.fetching = false));
      }
    },
    created() {
      this.fetchItems();
    }
  };
</script>

<style scoped></style>
